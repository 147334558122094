import './App.css';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

import MainContainer from './components/MainContainer/MainContainer';
import TopNavBar from './components/TopNavBar/TopNavBar';

export const SITE_JSON = process.env.REACT_APP_SITE_NAME_JSON;

function App() {
  let items = require('./data/' + SITE_JSON);
  const { site_name, site_copyright, site_footer_logo, site_logo, site_url } = items;
  return (
    <HelmetProvider>
      <TopNavBar logo_url={site_logo} site_url={site_url} />
      <MainContainer
        site_name={site_name}
        copyright={site_copyright}
        footer_logo={site_footer_logo}
      />
    </HelmetProvider>
  );
}

export default App;
